import { Icon } from "@/features/icons/components/IconLoader"
import { GenericDatePicker } from "@/shared/components/fields"
import { InfoItem } from "@/shared/components/items/InfoItem"
import { cn, isValidDate } from "@/utils/helpers"
import { faArrowRight } from "@awesome.me/kit-44b29310a6/icons/classic/solid"
import dayjs, { Dayjs } from "dayjs"
import { IFieldProps } from "../.."

export const DateField = ({
  value,
  onChange,
  field,
  readOnly,
  oldEntry,
  error,
}: IFieldProps) => {
  const parsedDate = parseDateField(value)
  const description = value
    ? dayjs(value).format(`DD MMM YYYY ${field?.showtime ? "HH:mm" : ""}`)
    : "No date selected"
  const parsedOldValue = parseDateField(oldEntry?.[field.key])
  const oldDescription =
    parsedOldValue &&
    dayjs(parsedOldValue).format(
      `DD MMM YYYY ${field?.showtime ? "HH:mm" : ""}`,
    )

  if (readOnly) {
    return (
      <InfoItem
        title={field.label}
        description={
          <div className="flex items-center gap-2 text-xs">
            {oldDescription && oldDescription !== description && (
              <>
                <span className="text-red-600">{oldDescription}</span>
                {oldEntry && (
                  <Icon
                    icon={faArrowRight}
                    size="xs"
                    className="text-custom-gray-dark"
                  />
                )}
              </>
            )}
            <span
              className={cn(
                "",
                oldDescription &&
                  oldDescription !== description &&
                  "text-green-600",
              )}
            >
              {description}
            </span>
          </div>
        }
      />
    )
  }

  return (
    <GenericDatePicker
      {...field}
      error={error}
      className={cn("border-red-800")}
      value={parsedDate === "Invalid value" ? null : parsedDate}
      onChange={(date: Dayjs) => onChange(date?.toISOString())}
    />
  )
}

export const parseDateField = (value: string | Date) => {
  if (
    Array.isArray(value) &&
    (isValidDate(value?.[0]) || isValidDate(value?.[0]?.[0]))
  ) {
    return Array.isArray(value[0]) ? value[0][0] : value[0]
  }
  if (typeof value !== "string" && value instanceof Date) {
    return "Invalid value"
  }
  return value
}
