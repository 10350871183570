export const RenderSelectionDot = ({
  color,
  selected,
}: {
  color: string
  selected?: boolean
}) => {
  const outlineStyling = "outline outline-offset-2 outline-2 outline-blue-500"

  color ??= "transparent"

  return (
    <div
      style={{
        backgroundColor: color,
        border: `${color === "transparent" ? "1px" : "0px"} solid #000`,
      }}
      className={`h-3 w-3 rounded-full ${selected ? outlineStyling : ""}`}
    ></div>
  )
}
