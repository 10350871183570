import { Icon } from "@/features/icons/components/IconLoader"
import { InfoItem } from "@/shared/components/items/InfoItem"
import { cn } from "@/utils/helpers"
import { faArrowRight } from "@awesome.me/kit-44b29310a6/icons/classic/solid"
import { Slider } from "antd"
import { IFieldProps } from "../.."

export const SliderField = ({
  value,
  onChange,
  field,
  readOnly,
  error,
  oldEntry,
}: IFieldProps) => {
  const parsedValue = parseSliderField(value)

  if (readOnly) {
    return (
      <InfoItem
        title={field.label}
        description={
          <div className="flex items-center gap-2 text-xs">
            {oldEntry?.[field.key] &&
              parseSliderField(oldEntry?.[field.key]) !== parsedValue && (
                <>
                  <span className="text-red-600">
                    {parseSliderField(oldEntry?.[field.key])}
                  </span>
                  {oldEntry && (
                    <Icon
                      icon={faArrowRight}
                      size="xs"
                      className="text-custom-gray-dark"
                    />
                  )}
                </>
              )}
            <span
              className={cn(
                "",
                oldEntry?.[field.key] &&
                  parseSliderField(oldEntry?.[field.key]) !== parsedValue &&
                  "text-green-600",
              )}
            >
              {parsedValue}
            </span>
          </div>
        }
      />
    )
  }

  return (
    <div
      className={cn(
        "border-custom-gray group relative flex min-h-[3rem] w-full flex-col gap-0.5 rounded-xl border bg-white px-4 py-3 ring-inset group-focus:ring-2",
        error && "border-red-500",
      )}
    >
      <span
        className={cn(
          "text-custom-gray-dark pointer-events-none bg-inherit text-xs font-light leading-none transition-all",
        )}
      >
        {field.label} ({value ? value + "%" : "Not defined"})
      </span>
      <Slider
        className="mx-4"
        value={value}
        onChange={onChange}
        step={5}
        tooltip={{ formatter: (value) => `${value}%` }}
        trackStyle={{ backgroundColor: "var(--color-blue-500)" }}
      />
    </div>
  )
}

export const parseSliderField = (value: number | string) => {
  if (!value || typeof value !== "number" || isNaN(Number(value))) {
    return "Invalid value"
  }
  return `${value}%`
}
