import { ImageWithFallback } from "@/utils/ImageWithFallback"

interface IInfoItem {
  title: string | JSX.Element | JSX.Element[]
  description: string | JSX.Element | JSX.Element[]
  image?: string
  onClick?(): void
}

export const InfoItem = ({ title, description, image, onClick }: IInfoItem) => (
  <div className="flex w-full gap-3">
    <div
      onClick={onClick}
      className={`flex w-full flex-col ${onClick ? "cursor-pointer" : ""}`}
    >
      <p className="mb-1 text-xs font-medium text-gray-400">{title}</p>
      {typeof description === "string" || typeof description === "number" ? (
        <div className="flex items-center gap-3 text-xs">
          {image && (
            <div className="relative w-fit">
              <ImageWithFallback
                src={image}
                alt="Image"
                className={"h-10 w-fit max-w-[100px] object-contain"}
                width="0"
                height="0"
              />
            </div>
          )}
          {description}
        </div>
      ) : (
        description
      )}
    </div>
  </div>
)
