import { FteCalculator } from "@/shared/components/fields"
import { InfoItem } from "@/shared/components/items/InfoItem"
import { IFieldProps } from "../.."

export const FTEField = ({ value, onChange, field, readOnly }: IFieldProps) => {
  const parsedValue = parseFTEField(value)

  if (readOnly) {
    return <InfoItem title={field.label} description={parsedValue} />
  }

  return (
    <FteCalculator
      {...field}
      value={Number(parsedValue)}
      onChange={(value: number) => onChange(value)}
    />
  )
}

export const parseFTEField = (value: number) => {
  if (typeof value !== "number" || isNaN(Number(value))) {
    return (0 / 37).toFixed(2)
  }
  return (Number(value) / 37).toFixed(2)
}
