import { Icon } from "@/features/icons/components/IconLoader"
import { TextEditor } from "@/shared/components/TextEditor"
import { TextArea } from "@/shared/components/fields"
import { cn } from "@/utils/helpers"
import { faArrowDown } from "@awesome.me/kit-44b29310a6/icons/classic/solid"
import { useEffect, useRef, useState } from "react"
import { IFieldProps } from "../.."

export const TextAreaField = ({
  value,
  onChange,
  field,
  readOnly,
  oldEntry,
  error,
}: IFieldProps) => {
  const [, setWidth] = useState(0)

  const ref = useRef<HTMLDivElement>(null)
  const hasSetDefault = useRef<boolean>(false)
  const parsedValue = parseTextAreaField(value)

  useEffect(() => {
    if (
      !value?.trim?.() &&
      !!field?.default_content &&
      !hasSetDefault.current
    ) {
      hasSetDefault.current = true
      onChange(field.default_content)
    }
  }, [value, field])

  useEffect(() => {
    const updateWidth = () => {
      if (ref.current) {
        setWidth(ref.current.clientWidth)
      }
    }

    window.addEventListener("resize", updateWidth)
    updateWidth()

    return () => window.removeEventListener("resize", updateWidth)
  }, [])

  if (readOnly) {
    return (
      <div className="flex w-full flex-col justify-center gap-2" ref={ref}>
        {field.label && (
          <p className="mb-2 text-xs font-medium text-gray-500">
            {field.label}
          </p>
        )}
        <div
          className={cn(
            "hidden",
            oldEntry?.[field.key] &&
              oldEntry?.[field.key] !== parsedValue &&
              "flex w-full flex-col items-center gap-2",
          )}
        >
          <div
            className={`rounded-[10px] border border-red-600`}
            style={{ width: ref?.current?.clientWidth }}
          >
            <TextEditor
              {...field}
              readOnly={readOnly}
              value={parseTextAreaField(oldEntry?.[field.key])}
              onEditorChange={(value) => {
                const event = {
                  target: {
                    value,
                  },
                }
                onChange?.(event.target.value)
              }}
            />
          </div>
          {oldEntry && (
            <Icon
              icon={faArrowDown}
              size="lg"
              className="text-custom-gray-dark"
            />
          )}
        </div>
        <div
          className={cn(
            oldEntry?.[field.key] &&
              oldEntry?.[field.key] !== parsedValue &&
              "rounded-[10px] border border-green-600",
          )}
          style={{ width: ref?.current?.clientWidth }}
        >
          <TextEditor
            {...field}
            readOnly={readOnly}
            value={parseTextAreaField(value)}
            onEditorChange={(value) => {
              const event = {
                target: {
                  value,
                },
              }
              onChange?.(event.target.value)
            }}
          />
        </div>
      </div>
    )
  }
  return (
    <TextArea
      {...field}
      error={error}
      value={parsedValue}
      onChange={(e) => onChange(e.target.value)}
      readOnly={readOnly}
    />
  )
}

export const parseTextAreaField = (value: string) => {
  if (typeof value !== "string" && value !== null && value !== undefined) {
    return "Invalid value"
  }
  return value
}
