import { Icon } from "@/features/icons/components/IconLoader"
import { InfoItem } from "@/shared/components/items/InfoItem"
import { cn } from "@/utils/helpers"
import { faArrowRight } from "@awesome.me/kit-44b29310a6/icons/classic/solid"
import { Switch } from "antd"
import React from "react"
import { IFieldProps } from "../.."

export const ToggleField = ({
  value,
  onChange,
  field,
  readOnly,
  error,
  oldEntry,
}: IFieldProps) => {
  const key = React.useId()
  const parsedValue = parseToggleField(value)
  if (readOnly) {
    return (
      <InfoItem
        title={field.label}
        description={
          <div className="flex items-center gap-2 text-xs">
            {oldEntry?.[field.key] &&
              parseToggleField(oldEntry?.[field.key]) !== parsedValue && (
                <>
                  <span className="text-red-600">
                    {parseToggleField(oldEntry?.[field.key])}
                  </span>
                  {oldEntry && (
                    <Icon
                      icon={faArrowRight}
                      size="xs"
                      className="text-custom-gray-dark"
                    />
                  )}
                </>
              )}
            <span
              className={cn(
                "",
                oldEntry?.[field.key] &&
                  parseToggleField(oldEntry?.[field.key]) !== parsedValue &&
                  "text-green-600",
              )}
            >
              {parsedValue}
            </span>
          </div>
        }
      />
    )
  }

  return (
    <div
      className={cn(
        "border-custom-gray group relative flex min-h-[3rem] w-full cursor-pointer items-center justify-between gap-0.5 rounded-xl border bg-white px-4 py-3 ring-inset group-focus:ring-2",
        error && "border-red-500",
      )}
      onClick={() => onChange(!value)}
    >
      <div className="flex flex-col gap-0.5">
        <span
          className={cn(
            "text-custom-gray-dark pointer-events-none bg-inherit text-xs font-light leading-none transition-all",
          )}
        >
          {field.label}
        </span>
        <div className="text-custom-gray-dark w-full select-none bg-white text-sm">
          {parseToggleField(value)}
        </div>
      </div>
      <Switch
        key={key}
        checked={value}
        onChange={(checked) => onChange(checked)}
        style={{
          backgroundColor: value
            ? "var(--color-blue-500)"
            : "rgba(0, 0, 0, 0.5)",
        }}
        size={"default"}
      />
    </div>
  )
}

export const parseToggleField = (value: boolean) => {
  return !!value ? "Yes" : "No"
}
