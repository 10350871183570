import { fetcher } from "@/utils/api"
import { faCheck } from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { faChevronDown } from "@awesome.me/kit-44b29310a6/icons/classic/solid"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { DropdownItem } from "../items/DropdownItem"
import { InfoItem } from "../items/InfoItem"
import { DropdownGeneric } from "./DropdownGeneric"

interface ICVPTemplates {
  value: string
  placeholder: string
  label?: string
  readOnly?: boolean
  onChange(value: string): void
}

type ICVTemplate = {
  _id: string
  name: string
  template_type: string
  docx: boolean
  used_by: string
  updated_ago: string
  updated_at: string
  created_at: string
}

export const CVPTemplates = ({
  value,
  placeholder,
  label,
  readOnly,
  onChange,
}: ICVPTemplates) => {
  const [templates, setTemplates] = useState<ICVTemplate[]>([])
  const template = templates.find((t) => t._id === value)

  useEffect(() => {
    fetchCVPTemplates()
  }, [])

  const fetchCVPTemplates = async () => {
    try {
      const fetchedTemplates = await fetcher("/cvpartners/templates")
      // Check if the fetched data is an array before updating state
      if (Array.isArray(fetchedTemplates)) {
        setTemplates(fetchedTemplates)
      } else {
        console.error("Data fetched is not an array:", fetchedTemplates)
      }
    } catch (error) {
      console.error("Error fetching templates:", error)
    }
  }

  const handleClick = (id: string) => {
    if (id === value) {
      onChange(null)
    } else {
      onChange(id)
    }
  }

  const getDropdownText = () => {
    if (!templates.length) {
      return "No templates available"
    }
    if (template) {
      return template.name
    }
    return placeholder
  }

  if (readOnly) {
    return (
      <InfoItem
        title={label}
        description={template?.name ?? "No template selected"}
      />
    )
  }

  return (
    <>
      {label && <p className="text-xs font-medium text-gray-500">{label}</p>}
      <DropdownGeneric
        collection={templates}
        renderItem={({ item }) => (
          <DropdownItem
            title={item.name}
            selected={item._id === value}
            icon={faCheck}
            onClick={() => handleClick(item._id)}
          />
        )}
        button={
          <div className="flex items-center justify-between gap-4 rounded-md border-b-2 p-2">
            <p className="text-sm">{getDropdownText()}</p>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        }
      />
    </>
  )
}
