import { FilePreviewModal } from "@/components/FilePreview"
import { IFile } from "@/features/storage/types"
import { MultiFileUpload } from "@/shared/components/fields"
import { downloadFile, downloadFiles } from "@/utils/api/storage"
import {
  faCloudDownload,
  faEye,
} from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { faCloudArrowDown } from "@awesome.me/kit-44b29310a6/icons/classic/solid"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Ring } from "@uiball/loaders"
import { useState } from "react"
import { IFieldProps } from "../.."

export const AttachmentsField = ({
  value,
  onChange,
  field,
  readOnly,
}: IFieldProps) => {
  const [previewFile, setPreviewFile] = useState<IFile>()
  const [loading, setLoading] = useState<Record<string, boolean>>({})

  const onRemove = (file: IFile) => {
    const filteredFiles = value?.filter?.((f) => f.uid !== file.uid)
    onChange(filteredFiles)
  }

  if (readOnly) {
    return (
      <>
        {previewFile && (
          <FilePreviewModal
            open={!!previewFile}
            setOpen={() => setPreviewFile(undefined)}
            files={[previewFile]}
          />
        )}
        <div className="w-full">
          <div className="flex items-center justify-between border-b pb-2 text-xs font-medium text-gray-400">
            <p>Attachments</p>
            {value?.length > 0 && (
              <button
                onClick={async () => {
                  setLoading((prev) => ({ ...prev, ["all"]: true }))
                  await downloadFiles(value)
                  setLoading((prev) => ({ ...prev, ["all"]: false }))
                }}
                className="flex items-center gap-1 text-blue-500 hover:opacity-80"
              >
                {loading["all"] ? (
                  <Ring color="var(--color-blue-500)" size={12} />
                ) : (
                  <>
                    <p>Download all files</p>
                    <FontAwesomeIcon icon={faCloudArrowDown} />
                  </>
                )}
              </button>
            )}
          </div>
          {!value?.length && (
            <p className="mt-1 text-xs">There are no attached files</p>
          )}
          <div className="mt-2 flex flex-col gap-2">
            {value?.map((file: IFile) => (
              <div key={file.uid} className="flex w-full items-center">
                <p className="flex-1 truncate text-xs">{file.filename}</p>
                <div className="flex min-w-fit items-center gap-1 text-xs">
                  {loading[file.uid] ? (
                    <Ring color="var(--color-blue-500)" size={10} />
                  ) : (
                    <FontAwesomeIcon
                      className="cursor-pointer transition-all duration-300 hover:text-blue-500"
                      onClick={async () => {
                        setLoading((prev) => ({ ...prev, [file.uid]: true }))
                        await downloadFile(file.uid)
                        setLoading((prev) => ({ ...prev, [file.uid]: false }))
                      }}
                      icon={faCloudDownload}
                    />
                  )}
                  {file.mimetype === "application/pdf" && (
                    <FontAwesomeIcon
                      className="cursor-pointer transition-all duration-300 hover:text-blue-500"
                      onClick={() => setPreviewFile(file)}
                      icon={faEye}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    )
  }

  return (
    <MultiFileUpload
      {...field}
      files={value}
      buttonText={field?.placeholder}
      onSuccess={(files) => onChange(files)}
      onRemove={onRemove}
      destination="root"
    />
  )
}

export const parseAttachmentsField = (value: IFile[]) => {
  if (!Array.isArray(value) || value?.every((v) => !!v?.filename)) {
    return "Invalid value"
  }
  return value.map((v) => v.filename).join(", ")
}
