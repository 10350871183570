import { CVPTemplates } from "@/shared/components/fields/CVPTemplates"
import { IFieldProps } from "../../.."

export const CVTemplatesField = ({
  value,
  onChange,
  field,
  readOnly,
}: IFieldProps) => {
  return (
    <CVPTemplates
      readOnly={readOnly}
      value={value}
      placeholder={field.placeholder}
      label={field.label}
      onChange={(value) => onChange(value)}
    />
  )
}
