import { AddTagButton } from "@/features/tags/components/AddTagButton"
import { TagsViewer } from "@/features/tags/components/TagsViewer"
import { InfoItem } from "@/shared/components/items/InfoItem"
import { cn } from "@/utils/helpers"
import { IFieldProps } from "../.."

export const TagsField = ({
  value,
  onChange,
  field,
  entry,
  readOnly,
  error,
}: IFieldProps) => {
  const parsedTags = parseTagsField(value)

  if (readOnly) {
    return (
      <InfoItem
        title={field.label}
        description={<TagsViewer tags={parsedTags} shownAmount={3} />}
      />
    )
  }

  return (
    <div
      className={cn(
        "border-custom-gray group relative flex min-h-[3rem] w-full items-center justify-between gap-0.5 rounded-xl border bg-white px-4 py-3",
        error && "border-red-500",
      )}
    >
      <div className="flex flex-col gap-1">
        <span
          className={cn(
            "text-custom-gray-dark pointer-events-none bg-inherit text-xs font-light leading-none transition-all",
          )}
        >
          {field.label}
        </span>

        <TagsViewer
          shownAmount={100}
          tags={parsedTags}
          source={entry?.uid}
          onRemove={(tag) => {
            const filteredTags = value?.filter((t) => t.uid !== tag)
            onChange(filteredTags)
          }}
        />
      </div>
      {!readOnly && (
        <AddTagButton
          source={entry?.uid}
          onSelect={(tag) => onChange([...parsedTags, tag])}
        />
      )}
    </div>
  )
}

export const parseTagsField = (value: any) => {
  if (!value || !Array.isArray(value) || value?.every?.((tag) => !tag?.name)) {
    return []
  }
  return value
}
