import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { IFieldProps } from "."
import {
  cvpartnerRegistry,
  defaultRegistry,
  tripletexRegistry,
} from "./registries"
import { FieldType } from "./types"

export type IFieldEntry = {
  key: string
  type: FieldType
  label: string
  description: string
  categories: string[]
  icon: IconDefinition
  component?(props: IFieldProps): JSX.Element
  parse?(value: any): any
}

export const FIELD_CATEGORIES = [
  "General",
  "Library",
  "_divider_",
  "CV Partner",
  "Tripletex",
]

export const fieldsRegistry: IFieldEntry[] = [
  ...defaultRegistry,
  ...cvpartnerRegistry,
  ...tripletexRegistry,
]
