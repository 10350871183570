import { IOption } from "@/features/datasources/types"
import { RenderSelectionDot } from "@/features/templates/components/RenderSelectionDot"
import { DropdownGeneric } from "@/shared/components/fields"
import { DropdownItem } from "@/shared/components/items/DropdownItem"
import { InfoItem } from "@/shared/components/items/InfoItem"
import { fetcher } from "@/utils/api"
import { cn } from "@/utils/helpers"
import { faChevronDown } from "@awesome.me/kit-44b29310a6/icons/classic/solid"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import useSWR from "swr"
import { IFieldProps } from "../.."

export const MultiSelectField = ({
  value = [],
  onChange,
  field,
  readOnly,
  error,
  oldEntry,
}: IFieldProps<string[]>) => {
  const parsedValue = parseMultiSelectField(value)
  const { data: options } = useSWR<IOption[]>(
    field?.uid ? `/datasources/options?field=${field.uid}` : null,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      refreshInterval: 0,
    },
  )

  const SelectedOptions = ({
    values,
    oldValues,
  }: {
    values?: string[]
    oldValues?: string[]
  }) => {
    const combinedUnique = [
      ...new Set([...(values || []), ...(oldValues || [])]),
    ]
    return (
      <>
        {combinedUnique?.map((uid) => {
          const option = options?.find((s) => s.uid === uid)
          if (!option) return null
          let textColor = "text-custom-gray-dark"
          if (oldValues) {
            if (!values.includes(uid) && oldValues.includes(uid)) {
              textColor = "text-red-600"
            } else if (values.includes(uid) && !oldValues.includes(uid)) {
              textColor = "text-green-600"
            }
          }
          return (
            <div
              key={field.uid + option.uid}
              className={`flex w-full items-center gap-1 bg-white ${textColor}`}
            >
              <RenderSelectionDot color={option?.color} />
              <>{option?.text}</>
            </div>
          )
        })}
      </>
    )
  }

  // Usage:

  if (parsedValue !== "Not defined") {
    value?.reduce((acc, uid) => {
      const option = options?.find((s) => s.uid === uid)
      if (!option) return acc
      acc.push(
        <div
          key={field.uid + option.uid}
          className="flex items-center gap-1 text-xs"
        >
          <>
            <RenderSelectionDot color={option?.color} />
            {option?.text}
          </>
        </div>,
      )
      return acc
    }, [])
  }

  if (readOnly) {
    const description =
      parsedValue === "Not defined" ? (
        "Not defined"
      ) : (
        <SelectedOptions values={value} oldValues={oldEntry?.[field.key]} />
      )
    return (
      <InfoItem
        title={field.label}
        description={<div className="text-xs">{description}</div>}
      />
    )
  }

  return (
    <div className="w-full">
      <DropdownGeneric
        collection={options}
        autoClose={false}
        renderItem={({ item }: { item: IOption }) => {
          const isSelected = value?.includes?.(item.uid)
          return (
            <DropdownItem
              key={item.uid}
              title={
                <>
                  <RenderSelectionDot
                    color={item.color}
                    selected={isSelected}
                  />{" "}
                  {item.text}
                </>
              }
              selected={isSelected}
              onClick={() => onChange(item.uid)}
            />
          )
        }}
        button={
          <>
            <div
              className={cn(
                "border-custom-gray group relative flex min-h-[3rem] w-full items-center justify-between gap-0.5 rounded-xl border bg-white px-4 py-3 ring-inset group-focus:ring-2",
                error && "border-red-500",
              )}
            >
              <div className="flex flex-col gap-0.5">
                <span
                  className={cn(
                    "text-custom-gray-dark pointer-events-none bg-inherit text-xs font-light leading-none transition-all",
                  )}
                >
                  {field.label}
                </span>
                <div className="flex flex-col items-center text-sm">
                  <SelectedOptions values={value} />
                </div>
              </div>
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </>
        }
      />
    </div>
  )
}

export const parseMultiSelectField = (value: any) => {
  if (!Array.isArray(value) || value?.every((v) => !!v?.text && !!v?.uid)) {
    return "Not defined"
  }
  return value
}
