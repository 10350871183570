import { IFieldProps } from "@/features/fields"
import { DropdownGeneric } from "@/shared/components/fields"
import { DropdownItem } from "@/shared/components/items/DropdownItem"
import { InfoItem } from "@/shared/components/items/InfoItem"
import {
  faCheck,
  faChevronDown,
} from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const DUE_DATE_TYPES = {
  DAYS: "Days",
  MONTHS: "Months",
  RECURRING_DAY_OF_MONTH: "Recurring day of month",
}

export const TripletexInvoiceDueDateTypeField = ({
  value,
  onChange,
  ...props
}: IFieldProps) => {
  const parsedValue = parseInvoiceDueDateType(value)

  if (props.readOnly) {
    return (
      <InfoItem
        title={props?.field?.label}
        description={parsedValue ? DUE_DATE_TYPES[parsedValue] : "Not defined"}
      />
    )
  }
  return (
    <div>
      <p className="text-xs font-medium text-gray-500">{props.field.label}</p>
      <DropdownGeneric
        collection={Object.entries(DUE_DATE_TYPES)}
        renderItem={({ item: [key, value] }) => (
          <DropdownItem
            title={value}
            selected={value === key}
            icon={faCheck}
            onClick={() => onChange(key)}
          />
        )}
        button={
          <div className="field-style field-input flex w-full cursor-pointer items-center justify-between gap-2">
            <p className="line-clamp-1">
              {DUE_DATE_TYPES[parsedValue]
                ? DUE_DATE_TYPES[parsedValue]
                : "Select due date type"}
            </p>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        }
      />
    </div>
  )
}

export const parseInvoiceDueDateType = (value: any) => {
  if (!DUE_DATE_TYPES[value]) {
    return null
  }
  return value
}
