import { IOption } from "@/features/datasources/types"
import { Icon } from "@/features/icons/components/IconLoader"
import { RenderSelectionDot } from "@/features/templates/components/RenderSelectionDot"
import { DropdownGeneric } from "@/shared/components/fields"
import { DropdownItem } from "@/shared/components/items/DropdownItem"
import { InfoItem } from "@/shared/components/items/InfoItem"
import { fetcher } from "@/utils/api"
import { cn } from "@/utils/helpers"
import {
  faArrowRight,
  faChevronDown,
} from "@awesome.me/kit-44b29310a6/icons/classic/solid"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import useSWR from "swr"
import { IFieldProps } from "../.."

export const SelectField = ({
  value,
  onChange,
  field,
  readOnly,
  error,
  oldEntry,
}: IFieldProps<string>) => {
  const { data: options } = useSWR<IOption[]>(
    field?.uid ? `/datasources/options?field=${field.uid}` : null,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  )

  const selected = options?.find((o) => o.uid === value)
  const oldSelected = options?.find((o) => o.uid === oldEntry?.[field.key])

  const buttonText = (selected) => (
    <div className="flex items-center gap-1 text-xs">
      {!!selected ? (
        <>
          <RenderSelectionDot color={selected.color} />
          {oldSelected && oldSelected.uid !== selected.uid && (
            <>
              <span className={cn("text-red-600")}>{oldSelected?.text}</span>
              <Icon
                icon={faArrowRight}
                size="sm"
                className="text-custom-gray-dark"
              />
            </>
          )}
          <span
            className={cn(
              oldEntry &&
                oldSelected?.uid !== selected?.uid &&
                "text-green-600",
            )}
          >
            {selected.text}
          </span>
        </>
      ) : readOnly ? (
        "Not defined"
      ) : (
        field.placeholder
      )}
    </div>
  )

  if (readOnly) {
    return <InfoItem title={field.label} description={buttonText(selected)} />
  }

  return (
    <>
      <DropdownGeneric
        collection={field?.datasource?.options}
        renderItem={({ item }: { item: IOption }) => {
          const option = field?.datasource?.options?.find(
            (o) => o.uid === item.uid,
          )
          return (
            <DropdownItem
              key={item.uid}
              title={
                <>
                  <RenderSelectionDot
                    color={option?.color}
                    selected={item.uid === value}
                  />{" "}
                  {item.text}
                </>
              }
              selected={item.uid === value}
              onClick={() => onChange(item.uid)}
            />
          )
        }}
        button={
          <div
            className={cn(
              "border-custom-gray group relative flex min-h-[3rem] w-full items-center justify-between gap-0.5 rounded-xl border bg-white px-4 py-3 ring-inset group-focus:ring-2",
              error && "border-red-500",
            )}
          >
            <div className="flex flex-col gap-0.5">
              <span
                className={cn(
                  "text-custom-gray-dark pointer-events-none bg-inherit text-xs font-light leading-none transition-all",
                )}
              >
                {field.label}
              </span>
              <div className="flex items-center">
                <div
                  className={`${!!selected ? "" : "text-custom-gray-dark"} text-md`}
                >
                  <div className="flex items-center gap-1 text-xs">
                    {!!selected ? (
                      <div className="text-custom-gray-dark flex w-full select-none items-center gap-1 bg-white text-sm placeholder-gray-500 outline-none focus:bg-white">
                        <RenderSelectionDot color={selected.color} />
                        {selected.text}
                      </div>
                    ) : (
                      field.placeholder
                    )}
                  </div>
                </div>
              </div>
            </div>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        }
      />
    </>
  )
}

export const parseSelectField = (value: IOption) => {
  return value?.text ?? "Invalid value"
}
