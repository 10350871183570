import { formatValue } from "../../../shared/components/inputs"

export const renderPricePill = ({ type }) => {
  switch (type) {
    case "fixed_pricing":
      return <div>Fixed price</div>
    case "range_pricing":
      return <div>Price range</div>
    case "free_pricing":
    default:
      return <div>Free pricing</div>
  }
}

export const renderPrice = ({
  type,
  min_price,
  max_price,
  currency,
  space,
  defaultValue,
}: {
  type: any
  min_price: number
  max_price: number
  currency: any
  space?: any
  defaultValue?: string
}) => {
  switch (type) {
    case "fixed_pricing":
      return (
        <span className="">
          {formatValue({
            value: min_price ?? 0,
            currency: currency.prefix,
            locale: space?.locale,
          })}
        </span>
      )
    case "range_pricing":
      return (
        <div className="text-sm">
          {formatValue({
            value: min_price ?? 0,
            currency: currency.prefix,
            locale: space?.locale,
          })}{" "}
          -{" "}
          {formatValue({
            value: max_price ?? 0,
            currency: currency.prefix,
            locale: space?.locale,
          })}
        </div>
      )
    case "free_pricing":
    default:
      return <div className="text-sm">{defaultValue}</div>
  }
}
