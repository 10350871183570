import { Icon } from "@/features/icons/components/IconLoader"
import { GenericDateRangePicker } from "@/shared/components/fields/GenericDateRangePicker"
import { InfoItem } from "@/shared/components/items/InfoItem"
import { cn } from "@/utils/helpers"
import { faArrowRight } from "@awesome.me/kit-44b29310a6/icons/classic/solid"
import dayjs, { Dayjs } from "dayjs"
import { IFieldProps } from "../.."

export const DateRangeField = ({
  value,
  onChange,
  field,
  readOnly,
  oldEntry,
  error,
}: IFieldProps) => {
  const dates = Array.isArray(value) ? value : [value, value]
  const parsedDates = parseDateRangeField(dates)
  const description = parsedDates
    ?.map?.((d) =>
      dayjs(d).format(`DD MMM YYYY ${field?.showtime ? "HH:mm" : ""}`),
    )
    .join(" - ")
  const oldDates = Array.isArray(oldEntry?.[field.key])
    ? oldEntry?.[field.key]
    : [oldEntry?.[field.key], oldEntry?.[field.key]]
  const parsedOldValue = parseDateRangeField(oldDates)
  const oldDescription = parsedOldValue
    ?.map?.((d) =>
      dayjs(d).format(`DD MMM YYYY ${field?.showtime ? "HH:mm" : ""}`),
    )
    .join(" - ")
  const hasDates = parsedDates?.some?.((d) => !!d)
  const handleChange = (dates: Dayjs[]) => {
    if (!dates || !dates?.length) {
      onChange([])
      return
    }
    onChange(dates?.map((d) => d?.toISOString()))
  }

  if (readOnly) {
    return (
      <InfoItem
        title={field.label}
        description={
          hasDates ? (
            <div className="flex items-center gap-2 text-xs">
              {oldDescription && oldDescription !== description && (
                <>
                  <span className="text-red-600">{oldDescription}</span>
                  {oldEntry && (
                    <Icon
                      icon={faArrowRight}
                      size="xs"
                      className="text-custom-gray-dark"
                    />
                  )}
                </>
              )}
              <span
                className={cn(
                  "",
                  oldDescription &&
                    oldDescription !== description &&
                    "text-green-600",
                )}
              >
                {description}
              </span>
            </div>
          ) : (
            "No dates selected"
          )
        }
      />
    )
  }

  return (
    <GenericDateRangePicker
      {...field}
      className={cn("field-input", error && "border-red-800")}
      placeholder={[field?.placeholder, field?.placeholder]}
      value={parsedDates}
      onChange={handleChange}
    />
  )
}

export const parseDateRangeField = (value: any) => {
  if (
    !Array.isArray(value) ||
    value?.length !== 2 ||
    value.some((v) => typeof v !== "string" && !(v instanceof Date))
  ) {
    return [] as any
  }
  return value
}
