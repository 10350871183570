import { Icon } from "@/features/icons/components/IconLoader"
import { NumberInput } from "@/shared/components/inputs"
import { InfoItem } from "@/shared/components/items/InfoItem"
import { cn } from "@/utils/helpers"
import { faArrowRight } from "@awesome.me/kit-44b29310a6/icons/classic/solid"
import { IFieldProps } from "../.."

export const NumberField = ({
  value,
  onChange,
  field,
  readOnly,
  oldEntry,
}: IFieldProps) => {
  const parsedValue = parseNumberField(value)

  if (readOnly) {
    return (
      <InfoItem
        title={field.label}
        description={
          <div className="flex items-center gap-2 text-xs">
            {oldEntry?.[field.key] && oldEntry?.[field.key] !== parsedValue && (
              <>
                <span className="text-red-600">
                  {parseNumberField(oldEntry?.[field.key])}
                </span>
                {oldEntry && (
                  <Icon
                    icon={faArrowRight}
                    size="xs"
                    className="text-custom-gray-dark"
                  />
                )}
              </>
            )}
            <span
              className={cn(
                "",
                oldEntry?.[field.key] &&
                  oldEntry?.[field.key] !== parsedValue &&
                  "text-green-600",
              )}
            >
              {parsedValue}
            </span>
          </div>
        }
      />
    )
  }

  return (
    <NumberInput
      {...field}
      value={parsedValue}
      onChange={(value) => onChange(value)}
    />
  )
}

export const parseNumberField = (value: number | string) => {
  if (isNaN(Number(value)) || typeof value !== "number") {
    return 0
  }
  return Number(value)
}

export const returnValueNumberField = (value: number | string): string => {
  if (isNaN(Number(value)) || typeof value !== "number") {
    return "0"
  }
  return value.toString()
}
