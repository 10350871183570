import { ICompany } from "@/features/companies/types"
import { AgreementOwner } from "@/shared/components/fields/AgreementOwner"
import { IFieldProps } from "../.."

export const AgreementOwnerField = ({
  value,
  onChange,
  field,
  readOnly,
}: IFieldProps) => {
  return (
    <AgreementOwner
      value={value}
      label={field?.label}
      placeholder={field?.placeholder}
      onChange={(value) => onChange(value)}
      readOnly={readOnly}
    />
  )
}

export const parseAgreementOwnerField = (value: ICompany) => {
  if (value?.name) {
    return "Invalid value"
  }
  return value.name
}
