import { Icon } from "@/features/icons/components/IconLoader"
import { PriceRange } from "@/shared/components/fields"
import { formatValue } from "@/shared/components/inputs"
import { InfoItem } from "@/shared/components/items/InfoItem"
import { formatTypeValues } from "@/shared/utils/helper"
import { cn } from "@/utils/helpers"
import { faArrowRight } from "@awesome.me/kit-44b29310a6/icons/classic/solid"
import { IFieldProps } from "../.."

export const PriceRangeField = ({
  value,
  onChange,
  field,
  readOnly,
  oldEntry,
  error,
}: IFieldProps) => {
  const parsedValue = parsePriceRangeField(value)
  const description =
    parsedValue &&
    parsedValue
      .map((value) => formatValue(formatTypeValues(value, field.type)))
      .join(" - ")
  const parsedOldValue = parsePriceRangeField(oldEntry?.[field.key])
  const oldDescription =
    parsedOldValue &&
    parsedOldValue
      .map((value) => formatValue(formatTypeValues(value, field.type)))
      .join(" - ")

  if (readOnly) {
    return (
      <InfoItem
        title={field.label}
        description={
          <div className="flex items-center gap-2 text-xs">
            {oldEntry &&
              oldEntry?.[field.key] &&
              description !== oldDescription && (
                <>
                  <span className="text-red-600">{oldDescription}</span>
                  <Icon
                    icon={faArrowRight}
                    size="xs"
                    className="text-custom-gray-dark"
                  />
                </>
              )}
            <span
              className={cn(
                "",
                oldEntry && oldDescription !== description && "text-green-600",
              )}
            >
              {description}
            </span>
          </div>
        }
      />
    )
  }

  return (
    <PriceRange
      {...field}
      value={parsedValue}
      onChange={(value) => onChange(value)}
      error={error}
    />
  )
}

export const parsePriceRangeField = (value: {
  value: number
  space?: Record<string, any>
  locale?: string
  label?: string
}) => {
  if (!value) return
  if (
    !Array.isArray(value) ||
    value?.every((v) => typeof v === "number" || typeof v === "string")
  ) {
    return [0, 0]
  }
  return value
}
