import { cn } from "@/utils/helpers"
import { TextareaHTMLAttributes } from "react"

type DefaultTextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label?: string
}

export const DefaultTextArea = ({
  label,
  readOnly,
  className,
  ...props
}: DefaultTextAreaProps) => {
  return (
    <div className="group">
      {label && (
        <p className="text-xs font-medium transition-all duration-300 group-focus-within:text-blue-500">
          {label}
        </p>
      )}
      <textarea
        className={cn(
          "w-full resize-none rounded-lg border-b-2 p-2 text-sm outline-none transition-all focus:border-blue-500 focus:bg-inherit",
          className,
        )}
        {...props}
      />
    </div>
  )
}
