import { faPrint } from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { CVTemplatesField } from "../components/fields"
import { IFieldEntry } from "../registry"

export const cvpartnerRegistry: IFieldEntry[] = [
  {
    key: "cvpartner_templates",
    type: "cvpartner_templates",
    label: "CV Templates",
    description: "List of CV templates to use when downloading",
    categories: ["CV Partner", "Misc"],
    icon: faPrint,
    component: CVTemplatesField,
    parse: () => "",
  },
]
