import { selectedTask } from "@/atoms/tasks"
import { PricingCard } from "@/components/projects/components/pricing/PricingCard"
import { IPrice } from "@/features/candidateflow/types"
import { getTagsBySources } from "@/features/tags/utils/getTagsBySources"
import { useEffect, useState } from "react"
import { useRecoilValue } from "recoil"
import { getPricesForTask } from "../../../features/pricings/utils/api"
import { Skeleton } from "../skeletons/Skeleton"

interface IPriceSelector {
  value: IPrice
  onChange(price: IPrice): void
  task: string
  autoSelect?: boolean
}

export const PriceSelector = ({
  value,
  onChange,
  task,
  autoSelect = true,
}: IPriceSelector) => {
  const [pricings, setPricings] = useState([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)
  const _selectedTask = useRecoilValue(selectedTask)
  const taskOwner = _selectedTask?._owner?.uid

  useEffect(() => {
    fetchPricings()
  }, [task])

  const fetchPricings = async () => {
    if (!task) {
      setIsLoading(false)
      return
    }
    try {
      const pricings: IPrice[] = await getPricesForTask(task)
      const params = taskOwner ? { space: taskOwner } : {}
      const tags = await getTagsBySources(
        pricings.map((v) => v.uid),
        params,
      )
      setPricings(
        pricings.map((price) => ({ ...price, tags: tags[price.uid] })),
      )
      if (autoSelect) {
        onChange(pricings[0])
      }
    } catch (error) {
      setError("Something went wrong, please try again")
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return <Skeleton className="mt-4 h-32" />
  }

  return (
    <div className="mt-2 grid grid-cols-1 gap-4">
      {pricings?.map((p) => (
        <PricingCard
          key={p.uid}
          price={p}
          selectedPrice={value}
          onChange={onChange}
        />
      ))}
      {!!error && <p className="mt-2 text-sm text-red-400">{error}</p>}
    </div>
  )
}
