import { getSpace } from "@/components/authentication/api"
import { companiesAtom } from "@/features/companies/components/atoms"
import { ICompany } from "@/features/companies/types"
import {
  ImageWithFallback,
  ORGANISATION_AVATERS,
} from "@/utils/ImageWithFallback"
import { faCheck } from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { Combobox } from "@headlessui/react"
import { Popover } from "antd"
import { orderBy } from "lodash"
import { useEffect, useState } from "react"
import { useRecoilValueLoadable } from "recoil"
import { DropdownItem } from "../items/DropdownItem"
import { RenderNA } from "../items/RenderNA"
import { ComboboxGeneric } from "./ComboboxGeneric"

interface IAgreementOwner {
  value: ICompany
  label?: string
  placeholder?: string
  onChange?(value: string): void
  readOnly?: boolean
}

const LABELS = ["is_supplier", "is_customer"]

export const AgreementOwner = ({
  value,
  label,
  placeholder,
  readOnly,
  onChange,
}: IAgreementOwner) => {
  const [selected, setSelected] = useState<{ uid: string; name: string }>()
  const ownerUid = typeof value === "string" ? value : value?.uid
  const { contents, state } = useRecoilValueLoadable(companiesAtom)
  const companies = state === "hasValue" && contents?.length > 0 ? contents : []
  const filteredCompanies = companies.filter((c) => !c?.deleted)
  const orderedCompanies = orderBy(filteredCompanies, ["name"], ["desc"])

  useEffect(() => {
    fetchSpace()
  }, [ownerUid])

  const fetchSpace = async () => {
    if (!ownerUid) return
    const space = await getSpace(ownerUid)
    setSelected(space)
  }

  return (
    <>
      {readOnly ? (
        <div className="">
          {selected?.uid ? (
            !label ? (
              <Popover
                content={selected?.name}
                overlayClassName="custom-popover"
                placement="top"
              >
                <div className="flex items-center">
                  <ImageWithFallback
                    src={ORGANISATION_AVATERS(selected?.uid)}
                    width={0}
                    height={0}
                    alt="Customer"
                  />
                </div>
              </Popover>
            ) : (
              <>
                <p className="text-xs font-medium text-gray-400">{label}</p>
                <div className="flex items-center gap-2">
                  <div className="w-6">
                    <ImageWithFallback
                      src={ORGANISATION_AVATERS(selected?.uid)}
                      width={0}
                      height={0}
                      alt="Customer"
                    />
                  </div>
                  <p className="text-xs text-gray-600">{selected?.name}</p>
                </div>
              </>
            )
          ) : (
            <RenderNA />
          )}
        </div>
      ) : (
        <div>
          {label && (
            <p className="text-xs font-medium text-gray-500">{label}</p>
          )}
          <ComboboxGeneric
            collection={orderedCompanies}
            selected={selected}
            placeholder={placeholder}
            nameExtractor={(item) => item?.name ?? ""}
            onSelect={(item: ICompany) => onChange?.(item.uid)}
            renderItem={(item) => (
              <Combobox.Option key={item.uid} value={item}>
                <DropdownItem
                  title={
                    <div className="flex items-center gap-2">
                      <div className="relative max-w-[50px]">
                        <ImageWithFallback
                          src={ORGANISATION_AVATERS(item.uid)}
                          fallback={""}
                          width={0}
                          height={0}
                          alt="Company"
                          className={"h-10 w-full max-w-[40px] object-contain"}
                        />
                      </div>
                      <div>
                        <p className="text-xs">{item.name}</p>
                        <div className="flex items-center gap-1">
                          <p key={label} className="text-[10px] text-gray-400">
                            {LABELS.filter((key) => !!item[key])
                              .map((key) =>
                                key === "is_supplier" ? "Supplier" : "Customer",
                              )
                              .join(", ")}
                          </p>
                        </div>
                      </div>
                    </div>
                  }
                  selected={item.uid === ownerUid}
                  icon={faCheck}
                />
              </Combobox.Option>
            )}
          />
        </div>
      )}
    </>
  )
}
