import { Viewer as ReactPdfViewer } from "@react-pdf-viewer/core"
import "@react-pdf-viewer/core/lib/styles/index.css"
import {
  ToolbarSlot,
  defaultLayoutPlugin,
} from "@react-pdf-viewer/default-layout"
import "@react-pdf-viewer/default-layout/lib/styles/index.css"
import { ErrorBoundary } from "@sentry/nextjs"
import { Ring } from "@uiball/loaders"
import "pdfjs-dist/build/pdf.worker.entry"
import { useMemo } from "react"

interface IPDFViewer {
  fileUrl: string
}

export const PDFViewer = ({ fileUrl }: IPDFViewer) => {
  const memoFileUrl = useMemo(() => fileUrl, [fileUrl])
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs() {
      return []
    },
    renderToolbar: (Toolbar) => {
      return (
        <Toolbar>
          {(props: ToolbarSlot) => {
            const {
              CurrentPageInput,
              Download,
              EnterFullScreen,
              GoToNextPage,
              GoToPreviousPage,
              NumberOfPages,
              Print,
              ShowSearchPopover,
              Zoom,
              ZoomIn,
              ZoomOut,
            } = props
            return (
              <div className="flex flex-wrap justify-between px-2">
                <div className="flex gap-1">
                  <div className="flex md:hidden">
                    <ShowSearchPopover />
                  </div>
                  <Zoom />
                  <div className="hidden md:flex">
                    <ShowSearchPopover />
                    <ZoomOut />

                    <ZoomIn />
                  </div>
                </div>
                <div className="my-1 flex items-center gap-1">
                  <GoToPreviousPage />
                  <CurrentPageInput /> / <NumberOfPages />
                  <GoToNextPage />
                </div>
                <div className="flex gap-1">
                  <div className="hidden md:flex">
                    <EnterFullScreen />
                  </div>
                  <Download />
                  <div className="hidden md:flex">
                    <Print />
                  </div>
                </div>
              </div>
            )
          }}
        </Toolbar>
      )
    },
  })

  return (
    <ErrorBoundary fallback={<p>Something went wrong</p>}>
      <div style={{ width: "100%", height: "100%" }}>
        <ReactPdfViewer
          renderLoader={() => <Ring color="var(--color-blue-500)" />}
          plugins={[defaultLayoutPluginInstance]}
          fileUrl={memoFileUrl}
        />
      </div>
    </ErrorBoundary>
  )
}
