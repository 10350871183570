import { spaceSettings } from "@/atoms/spaces"
import { pricingErrorsAtom } from "@/features/pricings/atoms"
import { cn } from "@/utils/helpers"
import { useEffect, useRef, useState } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { IPrice } from "../../../../features/candidateflow/types"
import {
  renderPrice,
  renderPricePill,
} from "../../../../features/pricings/components/Pill"
import { TagsViewer } from "../../../../features/tags/components/TagsViewer"
import { MoneyInput } from "../../../../shared/components/inputs"

interface IPricingCard {
  price: IPrice
  selectedPrice: IPrice
  onChange(price: IPrice): void
}

export const PricingCard = ({
  price,
  selectedPrice,
  onChange,
}: IPricingCard) => {
  const [input, setInput] = useState<IPrice>()
  const [error, setError] = useState<string>("")
  const _price = useRef<IPrice>(price)
  const space_settings = useRecoilValue(spaceSettings)
  const [pricingErrors, setPricingErrors] = useRecoilState(pricingErrorsAtom)

  useEffect(() => {
    if (selectedPrice && price.uid === selectedPrice.uid) {
      setInput({ ...price, ...selectedPrice })
    } else {
      setInput(price)
    }
  }, [price, selectedPrice])

  const handleChange = (e) => {
    const value = e.value
    const errors = { ...pricingErrors }
    delete errors[_price.current.uid]
    setPricingErrors(errors)
    setError("")

    if (_price.current.type !== "free_pricing") {
      if (Number(value) > _price.current.max_price) {
        setPricingErrors((prev) => ({
          ...prev,
          [_price.current.uid]:
            "Price must not be higher than the maximum price",
        }))
        setError("Price must not be higher than the maximum price")
        return
      }

      if (Number(value) < _price.current.min_price) {
        setPricingErrors((prev) => ({
          ...prev,
          [_price.current.uid]:
            "Price must not be higher than the maximum price",
        }))
        setError("Price must be higher than the minimum price")
        return
      }
    }

    //! This is bullshit. Had to search 3 hours for this calculation being done here...
    const newPrice = {
      ..._price.current,
      _modifications: {
        min_price: value - _price.current.min_price,
        max_price: value - _price.current.max_price,
        price: value,
      },
    }

    onChange(newPrice)
  }

  const onClick = () => {
    onChange(input)
  }

  if (!input) {
    return null
  }

  const isSelected = selectedPrice?.uid === price.uid

  return (
    <div
      onClick={onClick}
      className={cn(
        "border-custom-gray group relative flex min-h-[3rem] w-full flex-col gap-0.5 rounded-xl border bg-white px-4 py-3 ring-inset group-focus:ring-2",
        error && "border-red-500",
        isSelected && "shadow-custom-gray border-blue-500 shadow-inner",
      )}
    >
      <span
        className={cn(
          "text-custom-gray-dark pointer-events-none flex gap-2 bg-inherit text-xs font-light leading-none transition-all",
        )}
      >
        {renderPricePill(price)}{" "}
        {isSelected && <span className="text-blue-500">(Selected)</span>}
      </span>
      <div
        className={`flex gap-3 p-2 text-sm ${isSelected ? "text-blue-500" : "text-gray-400"} w-fit rounded-md`}
      >
        {renderPrice({ ...price, space: space_settings })}
      </div>
      {price.tags && price.tags.length > 0 && (
        <p className="text-xs font-medium opacity-80">
          <TagsViewer tags={price.tags} shownAmount={9999} fullWidth={true} />
        </p>
      )}
      {isSelected && price.type !== "fixed_pricing" && (
        <>
          <div className="">
            {!!error && <p className="text-xs text-red-500">{error}</p>}
          </div>
          <div className="items-center justify-between">
            <p className="text-xs font-medium">Your price:</p>
            <div className="mt-2 flex gap-1 pb-2 text-sm">
              <MoneyInput
                renderCurrencyContainer={false}
                value={{
                  value: input.max_price,
                  ...space_settings,
                  currency: price.currency?.prefix ?? space_settings.currency,
                }}
                onChange={handleChange}
                disableCurrencySelection={true}
                className="rounded-md bg-gray-200 px-2 py-1"
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}
