import { fetcher } from "../api"

/** CUSTOMERS */

export const updateCustomer = async (
  id: number,
  customer: Record<string, any>,
) => {
  return await fetcher(`/tripletex/customers/${id}`, {
    method: "PUT",
    body: JSON.stringify(customer),
  })
}

export const exportCompaniesToTripletex = async () => {
  return await await fetcher("/tripletex/customers/export", {
    method: "POST",
  })
}

export const exportCompanyToTripletex = async (company: string) => {
  return await await fetcher(`/tripletex/customers/export/${company}`, {
    method: "POST",
  })
}

export const importCustomersFromTripletex = async (accounts: string[]) => {
  return await fetcher("/tripletex/customers/import", {
    method: "POST",
    body: JSON.stringify({ accounts }),
  })
}

/** EMPLOYEES */

export const importEmployeesFromTripletex = async (accounts: string[]) => {
  return await fetcher("/tripletex/employees/import", {
    method: "POST",
    body: JSON.stringify({ accounts }),
  })
}

export const exportEmployeesFromTripletex = async () => {
  return await fetcher("/tripletex/employees/export", {
    method: "POST",
  })
}

/** PROJECTS */

export const importProjectsFromTripletex = async (template: string) => {
  return await fetcher(`/tripletex/projects/import`, {
    method: "POST",
    body: JSON.stringify({ template }),
  })
}

export const getProjectHourlyRates = async (projectId: string) => {
  return await fetcher(`/tripletex/projects/${projectId}/hourlyrates`)
}

/** RESOURCES */

export const getTripletexResource = async (resource: string) => {
  return await fetcher(`/tripletex/resources/${resource}`)
}
