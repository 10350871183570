import { Icon } from "@/features/icons/components/IconLoader"
import { TextInput } from "@/shared/components/fields"
import { InfoItem } from "@/shared/components/items/InfoItem"
import { cn } from "@/utils/helpers"
import { faArrowRight } from "@awesome.me/kit-44b29310a6/icons/classic/solid"
import { Popover } from "antd"
import { IFieldProps } from "../.."

export const TextField = ({
  value,
  onChange,
  field,
  readOnly,
  oldEntry,
  error,
  disabled,
}: IFieldProps) => {
  const parsedValue = parseTextField(value)
  if (readOnly) {
    return (
      <InfoItem
        title={field.label}
        description={
          <div className="flex items-center gap-2 text-xs">
            {oldEntry?.[field.key] && oldEntry?.[field.key] !== parsedValue && (
              <>
                <span className="text-red-600">
                  {parseTextField(oldEntry?.[field.key])}
                </span>
                {oldEntry && (
                  <Icon
                    icon={faArrowRight}
                    size="xs"
                    className="text-custom-gray-dark"
                  />
                )}
              </>
            )}
            <span
              className={cn(
                "",
                oldEntry?.[field.key] &&
                  oldEntry?.[field.key] !== parsedValue &&
                  "text-green-600",
              )}
            >
              {parsedValue}
            </span>
          </div>
        }
      />
    )
  }
  return (
    <TextInput
      {...field}
      disabled={disabled}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="overflow-hidden text-ellipsis"
      error={error}
    />
  )
}

export const TextFieldTable = ({ value }: { value: string }) => {
  return (
    <div>
      <Popover
        content={value}
        overlayClassName="custom-popover"
        placement="top"
      >
        <div className="max-w-[20rem] truncate">{value}</div>
      </Popover>
    </div>
  )
}

export const parseTextField = (value: any) => {
  const type = typeof value
  if (type !== "string" && type !== "number") {
    return "Invalid value"
  }
  return value
}
