import {
  faAddressBook,
  faAt,
  faBriefcase,
  faBuilding,
  faCalendar,
  faDollarSign,
  faHashtag,
  faMoneyBill,
  faPercentage,
  faStickyNote,
  faTag,
  faText,
  faToggleOff,
  faUser,
  faUserTie,
  faUsers,
} from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import {
  DateField,
  NumberField,
  TextField,
  ToggleField,
  parseTextField,
} from "../components/fields"
import {
  DefaultTextAreaField,
  parseDefaultTextAreaField,
} from "../components/fields/DefaultTextAreaField"
import {
  TripletexInvoiceDueDateTypeField,
  TripletexParticipants,
  TripletexResourceField,
} from "../components/fields/tripletex"
import { IFieldEntry } from "../registry"

export const tripletexRegistry: IFieldEntry[] = [
  {
    key: "tripletex_project_name",
    type: "tripletex_project_name",
    label: "Project name",
    description: "Name of the project",
    categories: ["Tripletex", "Text"],
    icon: faText,
    component: TextField,
    parse: parseTextField,
  },
  {
    key: "tripletex_description",
    type: "tripletex_description",
    label: "Project description",
    description: "Description of the project",
    categories: ["Tripletex", "Text"],
    icon: faStickyNote,
    component: DefaultTextAreaField,
    parse: parseDefaultTextAreaField,
  },
  {
    key: "tripletex_project_manager",
    type: "tripletex_project_manager",
    label: "Project manager",
    description: "Manager on the project",
    categories: ["Tripletex", "Misc"],
    icon: faUserTie,
    component: TripletexResourceField,
  },
  {
    key: "tripletex_department",
    type: "tripletex_department",
    label: "Department",
    description: "Which department the project belongs to",
    categories: ["Tripletex", "Misc"],
    icon: faBuilding,
    component: TripletexResourceField,
  },
  {
    key: "tripletex_main_project",
    type: "tripletex_main_project",
    label: "Main project",
    description: "Which project is the main project",
    categories: ["Tripletex", "Misc"],
    icon: faBriefcase,
    component: TripletexResourceField,
  },
  {
    key: "tripletex_start_date",
    type: "tripletex_start_date",
    label: "Start date",
    description: "Start date of the project",
    categories: ["Tripletex", "Date"],
    icon: faCalendar,
    component: DateField,
  },
  {
    key: "tripletex_end_date",
    type: "tripletex_end_date",
    label: "End date",
    description: "End date of the project",
    categories: ["Tripletex", "Date"],
    icon: faCalendar,
    component: DateField,
  },
  {
    key: "tripletex_customer",
    type: "tripletex_customer",
    label: "Customer",
    description: "Customer of the project",
    categories: ["Tripletex", "Misc"],
    icon: faUserTie,
    component: TripletexResourceField,
  },
  {
    key: "tripletex_is_closed",
    type: "tripletex_is_closed",
    label: "Is closed",
    description: "Shows if the project is closed",
    categories: ["Tripletex", "Toggle"],
    icon: faToggleOff,
    component: ToggleField,
  },
  {
    key: "tripletex_is_ready_for_invoicing",
    type: "tripletex_is_ready_for_invoicing",
    label: "Is ready for invoicing",
    description: "Shows if the project is ready for invoicing",
    categories: ["Tripletex", "Toggle"],
    icon: faToggleOff,
    component: ToggleField,
  },
  {
    key: "tripletex_is_internal",
    type: "tripletex_is_internal",
    label: "Is internal",
    description: "Shows if the project is internal",
    categories: ["Tripletex", "Toggle"],
    icon: faToggleOff,
    component: ToggleField,
  },
  {
    key: "tripletex_is_offer",
    type: "tripletex_is_offer",
    label: "Is offer",
    description: "Shows if the project is an offer",
    categories: ["Tripletex", "Toggle"],
    icon: faToggleOff,
    component: ToggleField,
  },
  {
    key: "tripletex_is_fixed_price",
    type: "tripletex_is_fixed_price",
    label: "Is fixed price",
    description: "Shows if the project is fixed price",
    categories: ["Tripletex", "Toggle"],
    icon: faToggleOff,
    component: ToggleField,
  },
  {
    key: "tripltex_project_category",
    type: "tripltex_project_category",
    label: "Project category",
    description: "Category of the project",
    categories: ["Tripletex", "Misc"],
    icon: faTag,
    component: TripletexResourceField,
  },
  {
    key: "tripletex_delivery_address",
    type: "tripletex_delivery_address",
    label: "Delivery address",
    description: "Delivery address of the project",
    categories: ["Tripletex", "Misc"],
    icon: faAddressBook,
    component: TripletexResourceField,
  },
  {
    key: "tripletex_reference",
    type: "tripletex_reference",
    label: "Reference",
    description: "Lorem ipsum",
    categories: ["Tripletex", "Text"],
    icon: faText,
    component: TextField,
  },
  {
    key: "tripletex_external_account_number",
    type: "tripletex_external_account_number",
    label: "External account number",
    description: "The external account number of the project",
    categories: ["Tripletex", "Number"],
    icon: faHashtag,
    component: NumberField,
  },
  {
    key: "tripletex_discount_percentage",
    type: "tripletex_discount_percentage",
    label: "Discount percentage",
    description: "If the project has a discount percentage",
    categories: ["Tripletex", "Number"],
    icon: faPercentage,
    component: NumberField,
  },
  {
    key: "tripletex_vat_type",
    type: "tripletex_vat_type",
    label: "Vat type",
    description: "Shows the vat type of the project",
    categories: ["Tripletex", "Text"],
    icon: faText,
    component: TripletexResourceField,
  },
  {
    key: "tripletex_fixed_price",
    type: "tripletex_fixed_price",
    label: "Fixed price",
    description: "Fixed price on the project",
    categories: ["Tripletex", "Number"],
    icon: faMoneyBill,
    component: NumberField,
  },
  {
    key: "tripletex_contribution_margin_percent",
    type: "tripletex_contribution_margin_percent",
    label: "Contribution margin percent",
    description: "Shows the contribution margin percent on the project",
    categories: ["Tripletex", "Number"],
    icon: faPercentage,
    component: NumberField,
  },
  {
    key: "tripletex_number_of_subprojects",
    type: "tripletex_number_of_subprojects",
    label: "Number of subprojects",
    description: "Shows the number of subprojects on the project",
    categories: ["Tripletex", "Number"],
    icon: faHashtag,
    component: NumberField,
  },
  {
    key: "tripletex_number_of_project_participants",
    type: "tripletex_number_of_project_participants",
    label: "Number of project participants",
    description: "Shows the number of project participants on the project",
    categories: ["Tripletex", "Number"],
    icon: faUsers,
    component: NumberField,
  },
  {
    key: "tripletex_currency",
    type: "tripletex_currency",
    label: "Currency",
    description: "Shows the currency on the project",
    categories: ["Tripletex", "Misc"],
    icon: faDollarSign,
    component: TripletexResourceField,
  },
  {
    key: "tripletex_markup_fees_earned",
    type: "tripletex_markup_fees_earned",
    label: "Mark up fees earned",
    description: "Shows the amount of mark up fees earned on the project",
    categories: ["Tripletex", "Number"],
    icon: faMoneyBill,
    component: NumberField,
  },
  {
    key: "tripletex_is_price_ceiling",
    type: "tripletex_is_price_ceiling",
    label: "Is price ceiling",
    description: "Shows if the project has a price ceiling",
    categories: ["Tripletex", "Toggle"],
    icon: faToggleOff,
    component: ToggleField,
  },
  {
    key: "tripletex_price_ceiling_amount",
    type: "tripletex_price_ceiling_amount",
    label: "Price ceiling amount",
    description: "Shows the price ceiling amount on the project",
    categories: ["Tripletex", "Number"],
    icon: faMoneyBill,
    component: NumberField,
  },
  {
    key: "tripletex_for_participants_only",
    type: "tripletex_for_participants_only",
    label: "For participants only",
    description: "Shows if the project is for participants only",
    categories: ["Tripletex", "Toggle"],
    icon: faToggleOff,
    component: ToggleField,
  },
  {
    key: "tripletex_participants",
    type: "tripletex_participants",
    label: "Participants",
    description: "The participants on the project",
    categories: ["Tripletex", "Misc"],
    icon: faUsers,
    component: TripletexParticipants,
  },
  {
    key: "tripletex_contact",
    type: "tripletex_contact",
    label: "Contact",
    description: "The contact on the project",
    categories: ["Tripletex", "Misc"],
    icon: faUser,
    component: TripletexResourceField,
  },
  {
    key: "tripletex_attention",
    type: "tripletex_attention",
    label: "Attention",
    description: "Lorem ipsum",
    categories: ["Tripletex", "Misc"],
    icon: faUser,
    component: TripletexResourceField,
  },
  {
    key: "tripletex_invoice_comment",
    type: "tripletex_invoice_comment",
    label: "Invoice comment",
    description: "Shows the invoice comment on the project",
    categories: ["Tripletex", "Text"],
    icon: faText,
    component: TripletexResourceField,
  },
  {
    key: "tripletex_invoice_duedate",
    type: "tripletex_invoice_duedate",
    label: "Invoice due date",
    description: "Shows the invoice due date on the project",
    categories: ["Tripletex", "Date"],
    icon: faCalendar,
    component: NumberField,
  },
  {
    key: "tripletex_invoice_duedate_type",
    type: "tripletex_invoice_duedate_type",
    label: "Invoice due date type",
    description: "Needed to show the invoice due date type",
    categories: ["Tripletex", "Date"],
    icon: faCalendar,
    component: TripletexInvoiceDueDateTypeField,
  },
  {
    key: "tripletex_invoice_receiver_email",
    type: "tripletex_invoice_receiver_email",
    label: "Invoice receiver email",
    description: "Shows the invoice receiver email on the project",
    categories: ["Tripletex", "Text"],
    icon: faAt,
    component: TripletexResourceField,
  },
]
