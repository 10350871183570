import { DefaultTextArea } from "@/shared/components/fields/DefaulTextArea"
import { InfoItem } from "@/shared/components/items/InfoItem"
import { IFieldProps } from "../.."

export const DefaultTextAreaField = ({
  value,
  onChange,
  field,
  readOnly,
}: IFieldProps) => {
  const parsedValue = parseDefaultTextAreaField(value)

  if (readOnly) {
    return <InfoItem title={field.label} description={parsedValue} />
  }
  return (
    <DefaultTextArea
      {...field}
      value={parsedValue}
      onChange={(e) => onChange(e.target.value)}
    />
  )
}

export const parseDefaultTextAreaField = (value: string) => {
  if (typeof value !== "string" && value !== null && value !== undefined) {
    return "Invalid value"
  }
  return value
}
